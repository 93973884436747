var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-swiper px-banner-swiper"},[(_vm.isEdit)?_c('div',{staticClass:"refreshModel"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"刷新輪播圖","placement":"right"}},[_c('el-button',{attrs:{"type":"success","icon":"el-icon-refresh","circle":""},on:{"click":_vm.updateSwiper}})],1),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("編輯模式下,將取消自動輪播")])],1):_vm._e(),_c('div',{ref:"swiper-container",staticClass:"swiper-container",class:{ autoplay: _vm.configs.autoplay }},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.configs.item),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide",class:item.position,on:{"click":function($event){return _vm.clickItem('swiper-slide', index)}}},[_c('div',{staticClass:"bg swiper-lazy",attrs:{"data-tab-color":item.tabColor,"data-background":_vm.isPc ? item.bg : item.bgYD || item.bg}}),_c('div',{staticClass:"swiper_body"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title",style:(`font-size:${_vm.$util.window.check(
                item.titleFontSize,
                item.pcTitleFontSize,
                item.padTitleFontSize
              )} !important;`)},[_c('span',{style:({ color: item.titleColor }),domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(item[`${_vm.nowL}title`] || item.title))}})]),_c('div',{staticClass:"desc",style:(`font-size:${_vm.$util.window.check(
                item.descFontSize,
                item.pcDescFontSize,
                item.padDescFontSize
              )} !important;`)},[_c('span',{style:({ color: item.descColor }),domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(item[`${_vm.nowL}desc`] || item.desc))}})]),_c('div',{staticClass:"btns"},_vm._l((item.btns),function(btn,bindex){return _c('el-button',{key:bindex,style:(`--btnThemeColor:${
                  btn.color || 'var(--themeColor)'
                };--btnThemeColor5:${
                  btn.color5 || 'var(--themeColor5)'
                };--btnThemeColorHover:${
                  btn.colorHover || 'var(--themeColorHover)'
                };--btnTextColor:${
                  (btn.style == 'plain' ? btn.color : btn.textColor) || '#FFF'
                };`),attrs:{"type":"primary","plain":btn.style == 'plain',"data-click":`${btn.go}`}},[_vm._v(_vm._s(btn[`${_vm.nowL}title`] || btn.title))])}),1)]),_c('div',{staticClass:"right"},[_c('img',{staticClass:"img swiper-lazy",attrs:{"data-src":_vm.isPc ? item.img.url : item.img.urlYD || item.img.url,"alt":item.img.alt}})])])])}),0),_c('div',{ref:"swiper-pagination",staticClass:"swiper-pagination"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    ref="nest-swiper-box"
    class="nest-swiper-box px-nest-swiper-box"
    :style="`background-image:url('${configs.bg}')`"
  >

    <!-- 編輯模式下 -->
    <div
      v-if="isEdit"
      class="refreshModel"
    >
      <el-tooltip
        class="item"
        effect="dark"
        content="刷新輪播圖"
        placement="right"
      >
        <el-button
          type="success"
          icon="el-icon-refresh"
          @click="updateSwiper"
          circle
        ></el-button>
      </el-tooltip>
      <span style="margin-left:10px">編輯模式下,將取消自動輪播</span>
    </div>

    <div
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      @click="clickItem('title')"
    ></div>
    <div
      class="desc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      @click="clickItem('title')"
    ></div>

    <div
      ref="swiper_box"
      class="swiper_box"
    >
      <div
        ref="swiper-container-one"
        class="swiper-container swiper-container-one"
      >
        <div class="swiper-wrapper">
          <!-- Swiper的内容 -->
          <div
            v-for="(item,index) in configs.items"
            :key="index"
            class="swiper-slide swiper-no-swiping"
          >
            <img
              class="big-img swiper-lazy"
              :data-index="index"
              :data-src="item.imgs[0].img"
            />
            <!-- <progressiveImage
              class="big-img"
              :data-index="index"
              :placeholder="$util.image.setOss(item.imgs[0].img)"
              :origin="item.imgs[0].img"
            /> -->
            <div class="item ">
              <img
                class="bigIcon"
                :src="item.bigIcon"
              />
              <div class="cell">
                <div class="label">{{item[`${nowL}title`] || item.title}}</div>
                <div class="value">{{item[`${nowL}desc`] || item.desc}}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref="swiper-pagination-m"
          class="swiper-pagination swiper-pagination-m"
        ></div>
      </div>
      <div
        ref="swiper-pagination"
        class="swiper-pagination"
      ></div>
    </div>

  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
// Import Swiper Vue.js components
import Swiper from 'swiper';
// Import Swiper styles
import 'swiper/css/swiper.css';

import ScrollTrigger from '@terwanerik/scrolltrigger';
const trigger = new ScrollTrigger();
import { gsap, TweenMax } from "gsap";
export default {
  name: "nest-swiper",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {}
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  watch: {
    "configs.items.length" () {
      this.updateSwiper();
    },
  },
  data () {
    return {
      active: 0,
      mySwiper: null
    }
  },
  computed: {
    isEdit () {
      return this.$store.getters.getIsEdit;
    }
  },
  mounted () {
    this.$changeLang.set("nestSwiperChange", () => {
      this.updateSwiper();
    })
    this.initSwiper();

    trigger.add(this.$refs['nest-swiper-box'], {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.2
          }
        },
        viewport: {
          y: (trigger, frame, direction) => {
            return 0;// trigger.visible ? 0 : 0.2
          }
        },
      },
      toggle: {
        callback: {
          in: () => {
            // 設置標題動畫
            TweenMax.from(this.$refs['nest-swiper-box'].querySelector('.title'), {
              opacity: 0,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            // 設置 描述動畫
            TweenMax.from(this.$refs['nest-swiper-box'].querySelector('.desc'), {
              opacity: 0,
              delay: 0.3,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            if (window.innerWidth > 768) {
              // 設置 item 動畫
              let items = this.$refs['nest-swiper-box'].querySelectorAll('.item.swiper-pagination-bullet');
              let tl = gsap.timeline();
              gsap.set(items, { x: 0 })
              for (let i in items) {
                if (items[i].nodeType === 1) {
                  tl.from(items[i], {
                    x: -60, // 目标位置
                    duration: 0.5, // 动画持续时间
                    ease: "power2.out" // 缓动函数
                  })
                }
              }
            } else {
              // 設置 描述動畫
              TweenMax.from(this.$refs['swiper_box'], {
                opacity: 0,
                delay: 0.3,
                y: 120,
                duration: 1,
                immediateRender: true
              })
            }

          },
          out: () => {
            // this.showNumber = false;
          },
        }
      }
    })
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
    initSwiper () {
      const vm = this;
      let w = window.innerWidth;

      let mySwiper = new Swiper(this.$refs['swiper-container-one'], {
        lazy: {
          loadPrevNext: true,
        },
        noSwiping: (this.isEdit || (w < 768)) ? false : true,
        direction: w > 768 ? 'vertical' : 'horizontal',
        effect: "fade",
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        speed: 1000,
        autoplay: this.isEdit ? false : {
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: w > 768 ? this.$refs['swiper-pagination'] : this.$refs['swiper-pagination-m'],
          clickable: true,
          renderBullet: w > 768 ? (index, className) => {
            console.log(className);
            // active == index ? item.bigIcon : 
            let item = this.configs.items[index];
            return `
            <div
            class="item ${className}"
            onmouseenter="this.click()"
          >
            <img class="icon" src="${item.icon}" />
            <img class="bigIcon" src="${item.bigIcon}" />
            <div class="cell">
              <div
                class="label"
              >${item[`${this.nowL}title`] || item.title}</div>
              <div
                class="value"
              >${item[`${this.nowL}desc`] || item.desc}</div>
            </div>
          </div>
          `;
          } : (index, className) => {
            return '<span class="' + className + '"></span>';
          },
        },
        on: {
          tap: function (swiper, event) {
            vm.clickItem('swiper-slide', swiper.target.dataset.index);
          },
        }
      })
      this.mySwiper = mySwiper;

      this.$refs['swiper_box'].onmouseover = function () {
        mySwiper.autoplay.stop();
      };

      this.$refs['swiper_box'].onmouseout = function () {
        mySwiper.autoplay.start();
      };

      new Swiper(this.$refs['swiper-container-two'], {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      })
    },
    updateSwiper () {
      this.mySwiper.destroy();
      setTimeout(() => {
        this.initSwiper();
      }, 300);
    }
  }
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .nest-swiper-box {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 256px;
    .refreshModel {
      position: absolute;
      top: 150px;
      left: 10px;
      z-index: 10;
    }
    .title {
      font-size: 48px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
      clear: both;
    }
    .desc {
      font-size: 26px;
      font-family: "Microsoft Yahei";
      font-weight: 200;
      line-height: 40px;
      color: #666666;
      margin-top: 16px;
      text-align: center;
    }
    .swiper_box {
      padding: 66px 116px 100px;
      display: flex;
      .swiper-container-one {
        margin-left: auto;
        height: 874px;
        margin-right: 0;
        .swiper-wrapper {
          width: 1019px;
          margin-right: 0;
        }
        .big-img {
          width: 1019px;
          height: 874px;
          border-radius: 15px;
        }
      }

      .swiper-container-two {
        width: 100%;
      }
      .swiper-pagination {
        & /deep/ .item {
          width: 627px;
          height: 100px;
          display: flex;
          margin-top: 13px;
          align-items: center;
          background: #ffffff;
          box-shadow: 0px 4px 26px 0px rgba(225, 233, 245, 0.3);
          border-radius: 15px;
          opacity: 1;
          position: relative;
          transition: 0.3s;
          &.active,
          &.swiper-pagination-bullet-active {
            height: 194px;
            .icon {
              // width: 104px;
              margin: 0 18px;
              // opacity: 0;
            }
            .bigIcon {
              width: 104px;
              left: 18px;
              top: 18px;
              opacity: 1;
            }
            .cell {
              margin-right: 26px;
              .label {
                // font-size: 40px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .value {
                height: 100px;
                font-size: 22px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 200;
                color: #666666;
                transition: 0.5s;
              }
            }
          }
          &:first-of-type {
            margin-top: 0;
          }
          .icon {
            width: 60px;
            transition: 0.2s;
            margin: 0 18px;
            opacity: 1;
            position: absolute;
            top: 18px;
          }
          .bigIcon {
            opacity: 0;
            transition: 0.5s;
            position: absolute;
            left: 18px;
            top: 18px;
            width: 60px;
          }
          .cell {
            transition: 0.3s;
            margin-left: 132px;
            .label {
              font-size: 34px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 600;
              // transition: 0.3s;
              color: #333333;
              text-align: left;
            }
            .value {
              // transition: 0.1s height;
              height: 0;
              font-size: 22px;
              overflow: hidden;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .px-nest-swiper-box {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .title {
      clear: both;
      font-size: 22px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
      padding-top: 24px;
    }
    .desc {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
      margin-top: 4px;
      padding: 0 50px;
    }
    .swiper_box {
      padding: 18px 12px;
      img {
        width: 100%;
        border-radius: 6px;
        height: calc(100vw / 1.24);
        object-fit: cover;
        background-color: #fff;
      }
      .swiper-slide {
        .item {
          padding: 20px 10px 17px;
          opacity: 0;
          transition: 0.3s;
          display: flex;
          align-items: center;
          .bigIcon {
            width: 40px;
            height: 40px;
          }
          .cell {
            margin-left: 10px;
            .label {
              font-size: 20px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .value {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        &.swiper-slide-active {
          .item {
            opacity: 1;
          }
        }
      }
    }

    .swiper-pagination-m {
      position: inherit;
      & /deep/ .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 0 4px;
        &.swiper-pagination-bullet-active {
          background-color: var(--themeColor);
        }
      }
    }
  }
}

@media screen and(max-width:600px) {
  .px-nest-swiper-box {
    .title {
      clear: both;
      padding-top: 24px !important;
      font-size: 20px !important;
      text-align: center;
    }
    .desc {
      font-size: 14px !important;
      text-align: center;
      margin-top: 4px !important;
    }
    .bigIcon {
      width: 42px !important;
      height: 42px !important;
    }
    .swiper-slide {
      .item {
        .cell {
          .label {
            font-size: 18px !important;
          }
          .value {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
</style>
<!--
 * @Author: lbh
 * @Date: 2023-08-04 12:06:47
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-16 15:06:00
 * @Description: 堆叠swiper
-->
<template>
  <div
    ref="stacked-layer-swiper"
    class="stacked-layer-swiper px-stacked-layer-swiper"
    :style="`background-color:${configs.bgColor};`"
    @click="clickItem('box')"
  >

    <div
      class="bigTitle"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></div>
    <div
      class="bigDesc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
    ></div>
    <div
      id="certify"
      ref="swiper_box"
    >
      <div
        ref="swiper-container"
        class="swiper-container"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(item,index) in configs.items"
            :key="index"
            class="swiper-slide"
          >
            <div class="cell">
              <img
                v-if="item.logo"
                class="swiper-lazy"
                :data-src="item.logo"
              />
              <div class="label_cell">
                <div
                  class="label"
                  v-html="$util.rex.getHtml(item[`${nowL}name`] || item.name)"
                ></div>
                <div
                  class="user"
                  v-html="$util.rex.getHtml(item[`${nowL}user`] || item.user)"
                ></div>
              </div>
            </div>
            <div class="img-c">
              <img src="../../assets/demo/stackedLayerSwiper/sls_d.png" />
            </div>
            <div
              class="desc"
              v-html="$util.rex.getHtml(item[`${nowL}text`] || item.text)"
            ></div>
            <div class="img-c">
              <img src="../../assets/demo/stackedLayerSwiper/sls_d.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
// Import Swiper Vue.js components
import Swiper from 'swiper';
// Import Swiper styles
import 'swiper/css/swiper.css';

import ScrollTrigger from '@terwanerik/scrolltrigger';
const trigger = new ScrollTrigger();
import { gsap, TweenMax } from "gsap";
export default {
  name: "stacked-layer-swiper",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {}
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      mySwiper: null
    }
  },
  watch: {
    "configs.items": {
      deep: true,
      handler (newValue, oldValue) {
        if (this.isEdit)
          this.updateSwiper();
      }
    }
  },
  computed: {
    isEdit () {
      return this.$store.getters.getIsEdit;
    }
  },
  mounted () {
    this.$changeLang.set("stackedSwiperChange", () => {
      this.updateSwiper();
    })
    this.initSwiper();

    trigger.add(this.$refs['stacked-layer-swiper'], {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.2
          }
        },
        viewport: {
          y: (trigger, frame, direction) => {
            return 0;// trigger.visible ? 0 : 0.2
          }
        },
      },
      toggle: {
        callback: {
          in: () => {
            // 設置標題動畫
            TweenMax.from(this.$refs['stacked-layer-swiper'].querySelector('.bigTitle'), {
              opacity: 0,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            // 設置 描述動畫
            TweenMax.from(this.$refs['stacked-layer-swiper'].querySelector('.bigDesc'), {
              opacity: 0,
              delay: 0.3,
              y: 120,
              duration: 1,
              immediateRender: true
            })

            // 設置 描述動畫
            TweenMax.from(this.$refs['stacked-layer-swiper'].querySelector('.swiper-container'), {
              opacity: 0,
              delay: 0.6,
              y: 120,
              duration: 1,
              immediateRender: true
            })
          },
          out: () => {
            // this.showNumber = false;
          },
        }
      }
    })
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
    initSwiper () {
      let w = window.innerWidth;

      if (w > 768) {
        let mySwiper = new Swiper(this.$refs['swiper-container'], {
          lazy: {
            loadPrevNext: true,
          },
          watchSlidesProgress: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: true,
          loopedSlides: 5,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false
          },
          on: {
            progress: function (progress) {
              for (let i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                var slideProgress = this.slides[i].progress;
                let modify = 1;
                if (Math.abs(slideProgress) > 1) {
                  modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                }
                let translate = (slideProgress * modify * 260);
                let scale = 1 - Math.abs(slideProgress) / 5;
                let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                slide.transform('translateX(' + translate / 196 + 'rem) scale(' + scale + ')');
                slide.css('zIndex', zIndex);
                slide.css('opacity', 1);
                if (Math.abs(slideProgress) > 3) {
                  slide.css('opacity', 0);
                }
              }
            },
            setTransition: function (transition) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i)
                slide.transition(transition);
              }
            }
          }
        })
        this.mySwiper = mySwiper;

        this.$refs['swiper_box'].onmouseover = function () {
          mySwiper.autoplay.stop();
        };

        this.$refs['swiper_box'].onmouseout = function () {
          mySwiper.autoplay.start();
        };

      } else {
        let allNum = this.configs.items.length,
          nowActive = 0;
        this.mySwiper = new Swiper(this.$refs['swiper-container'], {
          lazy: {
            loadPrevNext: true,
          },
          watchSlidesProgress: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: true,
          loopedSlides: 5,
          spaceBetween: w > 600 ? -120 : -90,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false
          },
          on: {
            progress: function (progress) {
              for (let i = 0; i < this.slides.length; i++) {
                let slide = this.slides.eq(i);
                let slideProgress = this.slides[i].progress;
                // 控制左右間距
                let modify = 1.3;
                if (Math.abs(slideProgress) > 1) {
                  modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                }
                let translate = slideProgress * modify * 90 + 'px';
                if (w > 600) translate = slideProgress * modify * 150 + 'px';
                // 控制縮放
                let scale = 1 - Math.abs(slideProgress) / 6;
                if (w > 600) scale = 1 - Math.abs(slideProgress) / 8;
                let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                slide.css('zIndex', zIndex);
                slide.css('opacity', 1);
                if (Math.abs(slideProgress) > 3) {
                  slide.css('opacity', 0);
                }
              }
            },
            setTransition: function (transition) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i)
                slide.transition(transition);
              }

            }
          }
        })
      }
    },
    updateSwiper () {
      this.mySwiper.destroy();
      setTimeout(() => {
        this.initSwiper();
      }, 300);
    }
  }

}
</script>

<style lang="less" scoped>
@media screen and(min-width:768px) {
  .stacked-layer-swiper {
    padding: 70px 0 70px;
    .bigTitle {
      font-size: 44px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }
    .bigDesc {
      font-size: 26px;
      font-family: "Microsoft Yahei";
      font-weight: 200;
      line-height: 40px;
      color: #666666;
      margin-top: 16px;
      text-align: center;
    }
    .swiper-container {
      padding-bottom: 30px;
      padding-top: 62px;
      // width: 1688px;
      .swiper-slide {
        width: 620px;
        background: #fff;
        box-shadow: 0px 4px 20px 0px rgba(150, 192, 255, 0.3);
        border-radius: 20px;
        align-self: center;
        &.swiper-slide-active {
          background: linear-gradient(180deg, #edf4ff 0%, #ffffff 100%);
          .cell {
            padding: 16px 37px;
            display: flex;
            justify-content: flex-start;
            img {
              width: 95px;
            }
            .label_cell {
              display: block;
              margin-left: 30px;
              .label {
                font-size: 36px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .user {
                font-size: 28px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-top: 7px;
              }
            }
          }
          .img-c {
            display: block;
            &:last-of-type {
              transform: rotate(180deg);
              padding-top: 12px;
              margin-top: -29px;
            }
            padding-top: 20px;
            img {
              width: 74px;
              margin-left: 37px;
            }
          }
          .desc {
            padding: 12px 60px;
            font-size: 28px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 200;
            color: #666666;
          }
        }
        .cell {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 35px;
          img {
            width: 154px;
          }
          .label_cell {
            display: none;
          }
        }
        .img-c {
          display: none;
        }
        .desc {
          font-size: 24px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 200;
          color: #666666;
          padding: 43px 31px 36px;
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .px-stacked-layer-swiper {
    padding: 34px 0 4px;
    .bigTitle {
      font-size: 22px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }
    .bigDesc {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
      margin-top: 4px;
      padding: 0 50px;
    }
    #certify {
      width: 100vw;
    }
    .swiper-container {
      padding: 18px 0 22px;
      .swiper-wrapper {
        .swiper-slide {
          background-color: #fff;
          box-shadow: 0px 4px 20px 0px rgba(150, 192, 255, 0.05);
          border-radius: 6px;
          width: 400px;
          &.swiper-slide-active {
            background: linear-gradient(180deg, #d7e9ff 0%, #ffffff 70%);
            // box-shadow: 0px 4px 20px 0px rgba(150, 192, 255, 0.1);
            box-shadow: 0px 4px 20px 0px rgba(150, 192, 255, 0.15);
          }
          .cell {
            display: flex;
            align-items: center;
            padding: 15px 0 0 20px;
            img {
              width: 50px;
              min-width: 50px;
              height: 50px;
              margin-right: 10px;
            }
            .label_cell {
              .label {
                font-size: 18px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
              }
              .user {
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
              }
            }
          }
          .desc {
            padding: 20px;
            font-size: 14px;
          }
          .img-c {
            &:last-of-type {
              transform: rotate(180deg);
              padding-top: 14px;
              // margin-top: -29px;
            }
            padding-top: 16px;
            img {
              width: 26px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width:600px) {
  .px-stacked-layer-swiper {
    padding: 34px 0 4px;
    .bigTitle {
      font-size: 20px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }
    .bigDesc {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
      margin-top: 4px;
    }
    #certify {
      width: 100vw;
    }
    .swiper-container {
      padding: 18px 0 22px;
      .swiper-wrapper {
        .swiper-slide {
          background-color: #fff;
          box-shadow: 0px 4px 20px 0px rgba(150, 192, 255, 0.05);
          border-radius: 6px;
          width: 250px;
          &.swiper-slide-active {
            background: linear-gradient(180deg, #d7e9ff 0%, #ffffff 70%);
            // box-shadow: 0px 4px 20px 0px rgba(150, 192, 255, 0.1);
            box-shadow: 0px 4px 20px 0px rgba(150, 192, 255, 0.15);
          }
          .cell {
            display: flex;
            align-items: center;
            padding: 15px 0 0 20px;
            img {
              width: 30px;
              min-width: 30px;
              height: 30px;
              margin-right: 10px;
            }
            .label_cell {
              .label {
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
              }
              .user {
                font-size: 12px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
              }
            }
          }
          .desc {
            padding: 0 10px;
            font-size: 12px;
          }
          .img-c {
            &:last-of-type {
              transform: rotate(180deg);
              padding-top: 12px;
              // margin-top: -29px;
            }
            padding-top: 16px;
            img {
              width: 26px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
<!--
 * @Author: lbh
 * @Date: 2023-08-02 18:05:36
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-16 09:33:56
 * @Description: file content
-->
<template>
  <div
    ref="suspension-module-box"
    class="suspension-module-box px-suspension-module-box"
    @click="clickItem('box')"
  >
    <div
      ref="tween-box"
      class="tween-box"
    >

      <div
        v-for="(item,index) in  configs.items"
        :key="index"
        class="item"
      >
        <!-- <img
          class="img"
          :src="item.img"
        /> -->
        <progressiveImage
          class="img"
          :src="item.img"
          :h="20"
        />
        <div class="right">
          <div class="label">
            <countTo
              v-if="showNumber"
              :ref="`countTo_${index}`"
              :startVal='0'
              :endVal='Number(item[`${nowL}number`] || item.number)'
              :duration='item.time || 2000'
              :autoplay="true"
              separator=""
              class="title"
            ></countTo>
            <span
              class="desc"
              v-text="item[`${nowL}desc`] || item.desc"
            ></span>
          </div>
          <div
            class="value"
            v-html="$util.rex.getHtml(item[`${nowL}content`] || item.content)"
          ></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
import countTo from 'vue-count-to';
import ScrollTrigger from '@terwanerik/scrolltrigger';
const trigger = new ScrollTrigger();
import { TweenMax } from "gsap";
export default {
  name: "suspension-module",
  mixins: [langMixin],
  components: { countTo },
  props: {
    configs: {
      default () {
        return {
          items: [
            {
              img: require('../../assets/demo/suspensionModule/sm_1.png'),
              number: '10',
              desc: '年+',
              content: '從2010年至今',
            },
            {
              img: require('../../assets/demo/suspensionModule/sm_2.png'),
              number: '1500',
              desc: '+',
              content: '服務餐廳數',
            },
            {
              img: require('../../assets/demo/suspensionModule/sm_3.png'),
              number: '60',
              desc: '萬+',
              content: '產生訂單數',
            },
            {
              img: require('../../assets/demo/suspensionModule/sm_4.png'),
              number: '10000',
              desc: '億+',
              content: '完成交易額',
            },
            {
              img: require('../../assets/demo/suspensionModule/sm_5.png'),
              number: '100',
              desc: '萬+',
              content: '累計用戶數',
            },
          ],
        }
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      showNumber: true
    }
  },
  watch: {
    "configs.items": {
      deep: true,
      handler (newValue, oldValue) {
        this.showNumber = false;
        this.$nextTick(() => {
          this.showNumber = true;
        })
      }
    }
  },
  computed: {
    isEdit () {
      return this.$store.getters.getIsEdit;
    }
  },
  mounted () {
    trigger.add(this.$refs['suspension-module-box'], {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.2
          }
        },
        viewport: {
          y: (trigger, frame, direction) => {
            return 0;// trigger.visible ? 0 : 0.2
          }
        },
      },
      toggle: {
        callback: {
          in: () => {
            this.showNumber = true;
            TweenMax.from(this.$refs['tween-box'], {
              opacity: 0,
              y: 60,
              duration: 1,
              immediateRender: true
            })
          },
          out: () => {
            this.showNumber = false;
          },
        }
      }
    })
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  }
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .suspension-module-box {
    width: 1688px;
    margin: -112px auto 0;
    position: relative;
    z-index: 10;
    height: 253px;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    .tween-box {
      background-color: #fff;
      box-shadow: 0px 8px 24px 0px rgba(96, 98, 102, 0.15);
      border-radius: 25px 25px 25px 25px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .item {
        display: flex;
        align-items: center;
        .img {
          width: 70px;
        }
        .right {
          margin-left: 20px;
          .label {
            width: 175px;
            .title {
              font-size: 46px;
              font-weight: 600;
              color: #333333;
              font-family: "dm";
              text-rendering: optimizelegibility;
            }
            .desc {
              font-size: 20px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
          }
          .value {
            font-size: 20px;
            // font-family: PingFang SC-Medium, PingFang SC;
            font-family: "Microsoft Yahei";
            font-weight: 500;
            color: #666666;
          }
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .px-suspension-module-box {
    width: calc(100% - 24px);
    margin: -4px auto 0;
    position: relative;
    z-index: 10;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    .tween-box {
      background-color: #fff;
      box-shadow: 0px 2px 6px 0px rgba(96, 98, 102, 0.08);
      border-radius: 6px;
      padding: 10px 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .item {
        display: flex;
        align-items: center;
        // &:nth-of-type(1) {
        //   display: none;
        // }
        .img {
          width: 70px;
          display: none;
        }
        .right {
          .label {
            .title {
              font-size: 24px;
              font-weight: 600;
              color: var(--themeColor);
              font-family: "dm";
              text-rendering: optimizelegibility;
              -webkit-font-smoothing: subpixel-antialiased;
            }
            .desc {
              font-size: 12px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
          }
          .value {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}
@media screen and(max-width:600px) {
  .px-suspension-module-box {
    width: calc(100% - 24px);
    margin: -4px auto 0;

    position: relative;
    z-index: 10;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    .tween-box {
      background-color: #fff;
      box-shadow: 0px 2px 6px 0px rgba(96, 98, 102, 0.08);
      border-radius: 6px;
      padding: 10px 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .item {
        display: flex;
        align-items: center;
        &:nth-of-type(1) {
          display: none;
        }
        .img {
          width: 70px;
          display: none;
        }
        .right {
          .label {
            .title {
              font-size: 20px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: var(--themeColor);
              min-width: 30px;
            }
            .desc {
              font-size: 12px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
          }
          .value {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            min-width: 70px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="box">
    <selfBannarSwiper
      :configs="configs"
      :Xindex="0"
    />
    <selfSuspensionModule
      :configs="suspensionConfigs"
      :Xindex="1"
    />
    <selfNestSwiper
      :configs="nestSwiperConfigs"
      :Xindex="2"
    />

    <selfTextCardModule
      :configs="textCardModuleConfigs"
      :Xindex="4"
    />

    <selfStackedLayerSwiper
      :configs="stackedLayerSwiperConfigs"
      :Xindex="5"
    />

    <selfCellularModule />

    <selfContactModule
      :configs="contactModuleConfigs"
      :Xindex="7"
    />
  </div>
</template>

<script>
import selfBannarSwiper from "../../components/do/bannarSwiper.vue"
import selfSuspensionModule from "../../components/do/suspensionModule.vue"
import selfNestSwiper from "../../components/do/nestSwiper.vue"
import selfTextCardModule from "../../components/do/textCardModule.vue"
import selfStackedLayerSwiper from "../../components/do/stackedLayerSwiper.vue"
import selfCellularModule from "../../components/do/cellularModule.vue"
import selfContactModule from "../../components/do/contactModule.vue"
export default {
  components: {
    selfBannarSwiper,
    selfSuspensionModule,
    selfNestSwiper,
    selfTextCardModule,
    selfStackedLayerSwiper,
    selfCellularModule,
    selfContactModule
  },
  data () {
    return {
      configs: {
        autoplay: true, // 是否自動輪播
        interval: 3, // 時間
        loop: true, // 是否 循環
        effect: "fade",// 
        item: [
          {
            // 標題
            title: 'RicePOS支持不同設備雲同步11',
            // 描述
            desc: '移動式多功能餐飲管理系統，搭建優良高效管理系統。 輕鬆管理所有方便快捷點餐服務，減低落錯單機會。 數據可視化，直觀看到數據變化趨勢。',
            // 展示類型 left:文字在左, right:文字在右
            position: 'left',
            // 按鈕組
            btns: [
              {
                //風格, 線性 plain 或是 實體 default
                style: 'default',
                title: '預約示範',
              },
              {
                //風格, 線性 plain 或是 實體 default
                style: 'plain',
                title: '查看詳情',
              },
            ],
            // 圖片
            img: {
              url: require('../../assets/demo/bannarSwiper/bs_img.png'),
              alt: '',
            },
            // 背景
            bg: require('../../assets/demo/bannarSwiper/bs_bg.png'),
          },
          {
            // 標題
            title: 'RicePOS支持不同設備雲同步',
            // 描述
            desc: '移動式多功能餐飲管理系統，搭建優良高效管理系統。 輕鬆管理所有方便快捷點餐服務，減低落錯單機會。 數據可視化，直觀看到數據變化趨勢。',
            // 展示類型 left:文字在左, right:文字在右
            position: 'right',
            // 按鈕組
            btns: [
              {
                //風格, 線性 plain 或是 實體 default
                style: 'default',
                title: '預約示範',
              },
              {
                //風格, 線性 plain 或是 實體 default
                style: 'plain',
                title: '查看詳情',
              },
            ],
            // 圖片
            img: {
              url: require('../../assets/demo/bannarSwiper/bs_img.png'),
              alt: '',
            },
            // 背景
            bg: require('../../assets/demo/bannarSwiper/bs_bg.png'),
          },
        ],
      },
      suspensionConfigs: {
        items: [
          {
            img: require('../../assets/demo/suspensionModule/sm_1.png'),
            number: '10',
            desc: '年+',
            content: '從2010年至今',
          },
          {
            img: require('../../assets/demo/suspensionModule/sm_2.png'),
            number: '1500',
            desc: '+',
            content: '服務餐廳數',
          },
          {
            img: require('../../assets/demo/suspensionModule/sm_3.png'),
            number: '60',
            desc: '萬+',
            content: '產生訂單數',
          },
          {
            img: require('../../assets/demo/suspensionModule/sm_4.png'),
            number: '10000',
            desc: '億+',
            content: '完成交易額',
          },
          {
            img: require('../../assets/demo/suspensionModule/sm_5.png'),
            number: '100',
            desc: '萬+',
            content: '累計用戶數',
          },
        ],
      },
      nestSwiperConfigs: {
        bg: require('../../assets/demo/nestSwiper/ns_bg.png'),
        title: '明星產品陣容',
        desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是 文字描述，可以自定義',
        items: [
          {
            icon: require('../../assets/demo/nestSwiper/ns_1.png'),
            bigIcon: require('../../assets/demo/nestSwiper/ns_big1.png'),
            title: '遙距排隊',
            desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
            imgs: [
              {
                img: require('../../assets/demo/nestSwiper/ns_img1.png'),
                alt: '',
              },
            ],
          },
          {
            icon: require('../../assets/demo/nestSwiper/ns_2.png'),
            bigIcon: require('../../assets/demo/nestSwiper/ns_big2.png'),
            title: '堂食點餐',
            desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
            imgs: [
              {
                img: require('../../assets/demo/nestSwiper/ns_img1.png'),
                alt: '',
              },
            ],
          },
          {
            icon: require('../../assets/demo/nestSwiper/ns_3.png'),
            bigIcon: require('../../assets/demo/nestSwiper/ns_big3.png'),
            title: '掃碼點餐',
            desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
            imgs: [
              {
                img: require('../../assets/demo/nestSwiper/ns_img1.png'),
                alt: '',
              },
            ],
          },
          {
            icon: require('../../assets/demo/nestSwiper/ns_4.png'),
            bigIcon: require('../../assets/demo/nestSwiper/ns_big4.png'),
            title: '外賣自取',
            desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
            imgs: [
              {
                img: require('../../assets/demo/nestSwiper/ns_img1.png'),
                alt: '',
              },
            ],
          },
          {
            icon: require('../../assets/demo/nestSwiper/ns_5.png'),
            bigIcon: require('../../assets/demo/nestSwiper/ns_big5.png'),
            title: '會員系統',
            desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
            imgs: [
              {
                img: require('../../assets/demo/nestSwiper/ns_img1.png'),
                alt: '',
              },
              {
                img: require('../../assets/demo/nestSwiper/ns_img1.png'),
                alt: '',
              },
            ],
          },
          {
            icon: require('../../assets/demo/nestSwiper/ns_6.png'),
            bigIcon: require('../../assets/demo/nestSwiper/ns_big6.png'),
            title: '更多產品',
            desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
            imgs: [
              {
                img: require('../../assets/demo/nestSwiper/ns_img1.png'),
                alt: '',
              },
            ],
          },
        ],
      },
      textCardModuleConfigs: {
        bg: require('../../assets/demo/textCardModule/tcm_bg.png'),
        title: '主流餐飲平臺隨時使用',
        desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是 文字描述，可以自定義',
        items: [
          {
            logo: require('../../assets/demo/textCardModule/tcm_1_logo.png'),
            title: 'FoodPanda',
            desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
            bg: require('../../assets/demo/textCardModule/tcm_1.png'),
          },
          {
            logo: require('../../assets/demo/textCardModule/tcm_2_logo.png'),
            title: 'FoodPanda',
            desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
            bg: require('../../assets/demo/textCardModule/tcm_2.png'),
          },
          {
            logo: require('../../assets/demo/textCardModule/tcm_3_logo.png'),
            title: 'FoodPanda',
            desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
            bg: require('../../assets/demo/textCardModule/tcm_3.png'),
          },
        ],
      },
      stackedLayerSwiperConfigs: {
        bg: '',
        bgColor: '#ECF4FF',
        title: '客戶評價',
        desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是 文字描述，可以自定義',
        items: [
          {
            logo: require('../../assets/demo/stackedLayerSwiper/sls_logo.png'),
            name: '帝皇集團',
            user: '姜女士',
            text: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義文字描述就看到划的痕迹這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述',
          },
          {
            logo: require('../../assets/demo/stackedLayerSwiper/sls_logo.png'),
            name: '帝皇集團',
            user: '姜女士',
            text: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義文字描述就看到划的痕迹這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述',
          },
          {
            logo: require('../../assets/demo/stackedLayerSwiper/sls_logo.png'),
            name: '帝皇集團',
            user: '姜女士',
            text: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義文字描述就看到划的痕迹這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述',
          },
          {
            logo: require('../../assets/demo/stackedLayerSwiper/sls_logo.png'),
            name: '帝皇集團',
            user: '姜女士',
            text: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義文字描述就看到划的痕迹這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述',
          },
          {
            logo: require('../../assets/demo/stackedLayerSwiper/sls_logo.png'),
            name: '帝皇集團',
            user: '姜女士',
            text: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義文字描述就看到划的痕迹這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述',
          },
        ],
      },

      contactModuleConfigs: {
        title: '聯係我們',
        desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
        bgColor: '#292D4F',
        contact: {
          title: 'Gingersoft聯絡方式',
          types: [
            {
              logo: '',
              address: '',
            },
            {
              logo: '',
              address: '',
            },
          ],
          labels: [
            {
              label: '聯絡電話：',
              value: '8200 2022',
            },
            {
              label: '聯繫Email：',
              value: 'equiry@gingersoft.com.hk',
            },
            {
              label: '聯絡地址：',
              value: '九龍長沙灣用康街51-63號時穎中心1401-2室',
            },
          ],
          address: {
            mapX: '',
            mapY: '',
          },
        },
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 300vh;
}
</style>
<!--
 * @Author: lbh
 * @Date: 2023-08-03 16:37:08
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-16 09:38:38
 * @Description: file content
-->
<template>
  <div
    ref="text-card-module-box"
    class="text-card-module-box px-text-card-module-box"
    :style="`background-image:url('${configs.bg}')`"
    @click="clickItem('box')"
  >
    <div
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    >
    </div>
    <div
      class="desc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
    >
    </div>
    <!-- PC -->

    <div
      v-if="isPc"
      ref="swiper_pc_box"
      class="swiper_box"
    >
      <div
        ref="swiper-pc-container"
        class="swiper-container swiper-container-one"
      >
        <div class="swiper-wrapper items">
          <!-- Swiper的内容 -->
          <div
            v-for="(item,index) in configs.items"
            :key="index"
            class="swiper-slide item"
          >
            <!-- <img
              class="bg_img"
              :src="item.bg"
            /> -->
            <progressiveImage
              class="bg_img"
              :src="item.bg"
              :height="100"
            />
            <div class="bg"></div>
            <div class="an">
              <div class="logo-box">
                <progressiveImage
                  class="img"
                  :src="item.logo"
                  :height="30"
                />
                <div
                  class="label"
                  v-text="item[`${nowL}title`] || item.title"
                ></div>
              </div>
            </div>
            <div
              class="content"
              v-html="$util.rex.getHtml(item[`${nowL}desc`] || item.desc)"
            >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移動 -->
    <div
      v-else
      ref="swiper_box"
      class="swiper_box"
    >
      <div
        ref="swiper-container"
        class="swiper-container swiper-container-one"
      >
        <div class="swiper-wrapper">
          <!-- Swiper的内容 -->
          <div
            v-for="(item,index) in configs.items"
            :key="index"
            class="swiper-slide"
          >
            <div class="bg_box">
              <img
                :src="item.bg"
                class="bg"
              >
              <img
                :src="item.logo"
                class="icon"
              />
              <div
                class="label"
                v-text="item[`${nowL}title`] || item.title"
              ></div>
            </div>
            <div
              class="content"
              v-html="$util.rex.getHtml(item[`${nowL}desc`] || item.desc)"
            >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
import ScrollTrigger from '@terwanerik/scrolltrigger';
const trigger = new ScrollTrigger();
// Import Swiper Vue.js components
import Swiper from 'swiper';
// Import Swiper styles
import 'swiper/css/swiper.css';
import { gsap, TweenMax } from "gsap";
export default {
  name: "text-card-module",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          bg: require('../../assets/demo/textCardModule/tcm_bg.png'),
          title: '主流餐飲平臺隨時使用',
          desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是 文字描述，可以自定義',
          items: [
            {
              logo: require('../../assets/demo/textCardModule/tcm_1_logo.png'),
              title: 'FoodPanda',
              desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
              bg: require('../../assets/demo/textCardModule/tcm_1.png'),
            },
          ],
        }
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  computed: {
    isEdit () {
      return this.$store.getters.getIsEdit;
    },
    isPc () {
      return window.innerWidth > 768;
    }
  },
  mounted () {

    if (!this.isPc) {
      this.mySwiper = new Swiper(this.$refs['swiper-container'], {
        slidesPerView: 1.6,
        centeredSlides: true,
        spaceBetween: 7,
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
      })

    } else {
      this.mySwiper = new Swiper(this.$refs['swiper-pc-container'], {
        slidesPerView: 3.1,
        spaceBetween: 30,
        freeMode: true,
      })
    }

    trigger.add(this.$refs['text-card-module-box'], {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.2
          }
        },
        viewport: {
          y: (trigger, frame, direction) => {
            return 0;// trigger.visible ? 0 : 0.2
          }
        },
      },
      toggle: {
        callback: {
          in: () => {
            // 設置標題動畫
            TweenMax.from(this.$refs['text-card-module-box'].querySelector('.title'), {
              opacity: 0,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            // 設置 描述動畫
            TweenMax.from(this.$refs['text-card-module-box'].querySelector('.desc'), {
              opacity: 0,
              delay: 0.3,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            if (window.innerWidth > 768) {
              // 設置 item 動畫
              let items = this.$refs['text-card-module-box'].querySelectorAll('.item');
              let tl = gsap.timeline();
              for (let i in items) {
                if (items[i].nodeType === 1) {
                  if (i == 0) {
                    tl.from(items[i], {
                      y: 120,
                      duration: 0.5,
                    })
                  } else {
                    tl.from(items[i], {
                      y: 120,
                      duration: 0.5,
                    }, ">-0.2")
                  }

                }
              }
            } else {
              TweenMax.from(this.$refs['swiper_box'], {
                opacity: 0,
                delay: 0.3,
                y: 120,
                duration: 1,
                immediateRender: true
              })
            }
          },
        }
      }
    })
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.text-card-module-box {
  overflow: hidden;
}
@media screen and(min-width:768px) {
  .text-card-module-box {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 116px 100px;
    .title {
      text-align: center;
      font-size: 44px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .desc {
      font-size: 26px;
      font-family: "Microsoft Yahei";
      font-weight: 200;
      line-height: 40px;
      color: #666666;
      margin-top: 16px;
      text-align: center;
    }

    .items {
      // justify-content: space-between;
      margin-top: 62px;
      .item {
        // width: 523px;
        // min-width: 523px;
        height: 626px;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          .bg_img {
            transform: scale(1.2);
          }
          .bg {
            background-color: rgba(0, 0, 0, 0.2);
          }
          .an {
            left: 30px;
            top: 69px;
            transform: translateX(0) translateY(0);
            width: 100%;
            align-items: baseline;

            .logo-box {
              flex-direction: row;
              align-items: initial;
              justify-content: initial;
              width: calc(100% - 60px);
              .img {
                width: 80px;
                min-width: 80px;
                height: 80px;
              }
              .label {
                margin-left: 20px;
                margin-top: 0;
                font-size: 32px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                text-align: left;
              }
            }
          }
          .content {
            //
            top: 210px;
          }
        }
        .bg_img {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 2;
          transition: 0.5s;
        }
        .bg {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 5;
        }
        .an {
          position: absolute;
          z-index: 10;
          width: 90%;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          z-index: 10;
          transition: 0.5s;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .logo-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .img {
              width: 150px;
              min-width: 150px;
              height: 150px;
              transition: 0.3s;
            }
            .label {
              font-size: 32px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
              transition: 0.5s;
              margin-top: 10px;
              text-align: center;
              // height: 56px;
              overflow: hidden;
            }
          }
        }
        .content {
          font-size: 26px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          transition: 0.3s;
          width: calc(100% - 60px);
          position: absolute;
          z-index: 8;
          left: 30px;
          top: 1000px;
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .px-text-card-module-box {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 34px 0 22px;
    .title {
      font-size: 22px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }
    .desc {
      margin-top: 4px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
      padding: 0 50px;
    }
    .swiper_box {
      margin-top: 18px;
      .swiper-container {
        .swiper-wrapper {
          padding-bottom: 4px;
          .swiper-slide {
            --siW: 66vw;
            border-radius: 6px;
            background-color: #fff;
            width: var(--siW);
            box-shadow: 0px 2px 10px 0px rgba(48, 48, 48, 0.04);
            height: auto;
            .bg_box {
              height: calc(var(--siW) / 2.56);
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .bg {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
                z-index: 0;
                border-radius: 6px 6px 0 0;
              }
              .icon {
                width: 44px !important;
                height: 44px !important;
                position: relative;
                z-index: 3;
              }
              .label {
                position: relative;
                z-index: 3;
                font-size: 16px !important;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                margin-top: 6px;
              }
            }
            .content {
              padding: 10px;
              font-size: 14px !important;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }
    }
  }
}
@media screen and(max-width:600px) {
  .px-text-card-module-box {
    .title {
      font-size: 20px;
    }
    .desc {
      font-size: 14px;
      padding: 0 50px;
    }
    .swiper_box {
      .swiper-container {
        .swiper-wrapper {
          .swiper-slide {
            .bg_box {
              .icon {
                width: 40px !important;
                height: 40px !important;
              }
              .label {
                font-size: 14px !important;
                text-align: center !important;
              }
            }
            .content {
              font-size: 12px !important;
              color: #333 !important;
            }
          }
        }
      }
    }
  }
}
</style>